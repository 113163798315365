import React from "react";

export default function Input({
  width = "w-full",
  height = "h-[50px]",
  opacity = "",
  placeholder,
  rounded = "rounded-[34px]",
  onChange,
  type,
  value,
  name,
  maxLength,
  bg = "bg-alto/5",
  ml = "ml-0",
  mr = "mr-0",
  mt = "mt-0",
  mb = "mb-0",
  autoFocus = false,
}) {
  return (
    <input
      type={type}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      name={name}
      autoFocus={autoFocus}
      maxLength={maxLength}
      className={`${width} ${height} ${rounded} ${mt} ${mr} ${mb} ${ml} ${bg} text-[13px] active:bg-alto/5  focus:bg-alto/5 autofill:bg-alto/5 leading-5 px-7 tracking-[0.135em] outline-none text-white`}
    />
  );
}
