import React, { Fragment, useState } from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Input from "../components/common/Input";
import Select from "../components/common/select";
import Button from "../components/common/Button";
import OurClients from "../components/common/OurClients";
import { emailValidate } from "../utils/validator";
import { API } from "../config/api";
import Alert from "../components/common/popup/Alert";

export default function ContactUs({ data: { sanityContactUs } }) {
  const title = sanityContactUs?.title?.split(" ");
  const [error, setError] = useState(null);
  const [communicationMethod, setCommunicationMethod] = useState("");
  const [describeYou, setDescribeYou] = useState("");
  const [assets, setAsset] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    company_name: "",
  });

  const handleChange = (evt) => {
    setError(null);
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleSubmit = async () => {
    const { first_name, last_name, email, company_name } = state;

    if (
      !first_name ||
      !last_name ||
      !email ||
      !company_name ||
      communicationMethod === "" ||
      describeYou === "" ||
      assets === ""
    ) {
      setError("All fields required");
    } else if (!emailValidate(email)) {
      setError("inValid email address");
    } else {
      setError(null);
      setLoading(true);
      const data = {
        ...state,
        communication_method: communicationMethod,
        describe_you: describeYou,
        value_of_asset: assets,
      };

      try {
        await API.post("/contact-us", data);

        setLoading(false);
        setState({
          first_name: "",
          last_name: "",
          email: "",
          company_name: "",
        });
        setCommunicationMethod("");
        setDescribeYou("");
        setAsset("");
        setPopup(true);
      } catch (err) {
        setLoading(false);
        if (err?.response?.data) {
          setError(err.response.data?.error);
        }
      }
    }
  };

  return (
    <Layout>
      <div className="mt-10 md:mt-[97px] mb-8 md:mb-[93px]">
        <OurClients clients={sanityContactUs?.clientSec} />
        <div className="relative">
          <div className="absolute hidden md:block top-0 right-0 bottom-0 left-0 -z-10 min-h-[313px]  wave opacity-30">
            <StaticImage
              src="../images/hero.webp"
              alt="contact-us"
              placeholder="blurred"
            />
          </div>

          <div className="container max-w-[1366px] grid grid-cols-1 md:grid-cols-12">
            <div className="md:col-span-6 xl:col-span-7 md:border-r px-7 md:pl-[50px] md:pr-[50px] xl:pr-0 xl:pl-[115px]">
              <div className="max-w-[558px]">
                <p className="font-medium leading-[54px] text-3xl lg:text-[36px] text-white">
                  {title?.map((item, index) => (
                    <span
                      key={index}
                      className={`${
                        item.indexOf("Thunder") !== -1 && "txt-gradient-yellow"
                      }`}
                    >
                      {item}{" "}
                    </span>
                  ))}
                </p>
                <p className="font-normal leading-6 text-base text-white mt-[22px]">
                  {sanityContactUs?.content?.map((item, index) => (
                    <Fragment key={index}>
                      <span className="block">{item?.children[0]?.text}</span>
                      <br />
                    </Fragment>
                  ))}
                </p>
              </div>
            </div>
            <div className="md:col-span-6 xl:col-span-5 pb-[100px] mt-7 md:mt-0">
              <div className="px-7 lg:pr-0 lg:pl-[63px] md:max-w-[442px]">
                <p className="font-normal leading-6 text-base text-white">
                  Schedule a time to walk through our staking process with a
                  Thunderhead.
                </p>
                {error !== null && (
                  <span className="text-red-700 opacity-80 -mb-5   block font-bold text-sm">
                    {error}
                  </span>
                )}
                <div className="mt-7">
                  <div className="flex flex-col sm:flex-row md:flex-col lg:flex-row items-center mb-[17px]">
                    <Input
                      placeholder="First Name"
                      type="text"
                      mr="mr-[14px] sm:mr-[14px] md:mr-0 lg:mr-[14px]"
                      name={"first_name"}
                      value={state.first_name}
                      onChange={handleChange}
                    />
                    <Input
                      placeholder="Last Name"
                      type="text"
                      mt="mt-[17px] sm:mt-0 md:mt-[17px] lg:mt-0"
                      name={"last_name"}
                      value={state.last_name}
                      onChange={handleChange}
                    />
                  </div>
                  <Input
                    placeholder="Company Name"
                    type="text"
                    name={"company_name"}
                    value={state.company_name}
                    onChange={handleChange}
                  />
                  <Input
                    placeholder="Email"
                    type="email"
                    mt="mt-[17px]"
                    name={"email"}
                    value={state.email}
                    onChange={handleChange}
                  />
                  <Select
                    mt="mt-[17px]"
                    value={communicationMethod}
                    onChange={(e) => setCommunicationMethod(e.target.value)}
                  >
                    <option value="">Preferred Communication</option>
                    <option value="Phone Number">Phone Number</option>
                    <option value="Whatsapp">Whatsapp</option>
                    <option value="Telegram">Telegram</option>
                    <option value="Signal">Signal</option>
                  </Select>
                  <Select
                    mt="mt-[17px]"
                    value={describeYou}
                    onChange={(e) => setDescribeYou(e.target.value)}
                  >
                    <option value="">Which Best Describe You?</option>
                    <option value="Individual Investor">
                      Individual Investor
                    </option>
                    <option value="Crypto OR Venture Fund">
                      Crypto OR Venture Fund
                    </option>
                    <option value="Exchange">Exchange</option>
                    <option value="Degen">Degen</option>
                    <option value="Something Else">Something Else</option>
                  </Select>
                  <Select
                    mt="mt-[17px]"
                    mb="mb-[32px]"
                    value={assets}
                    onChange={(e) => setAsset(e.target.value)}
                  >
                    <option value="">Value Of Assets</option>
                    <option value="$10 Million +">$10 Million +</option>
                    <option value="$1 Million - 10 Million">
                      $1 Million - 10 Million
                    </option>
                    <option value="$250k - $1 Million">
                      $250k - $1 Million
                    </option>
                    <option value="$10k - 250k">$10k - 250k</option>
                    <option value="<$10k">{`<$10k`}</option>
                  </Select>
                  <Button
                    text={loading ? "LOADING..." : "SUBMIT"}
                    onClick={handleSubmit}
                    disable={loading}
                    width="w-full"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {popup && (
        <Alert
          message={
            "Thank you for contacting Thunderstake. We will be contacting you as quickly as possible"
          }
          onClose={() => setPopup(false)}
        />
      )}
    </Layout>
  );
}

export const query = graphql`
  query {
    sanityContactUs {
      title
      clientSec {
        title
        clientList {
          title
          logo {
            asset {
              gatsbyImageData(fit: CLIP, placeholder: BLURRED, height: 61)
            }
          }
        }
      }
      content {
        children {
          text
        }
      }
    }
  }
`;
