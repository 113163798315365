import React from "react";
import "./style.css";

export default function Select({
  width = "w-full",
  height = "h-[50px]",
  rounded = "rounded-[34px]",
  onChange,
  value,
  name,

  ml = "ml-0",
  mr = "mr-0",
  mt = "mt-0",
  mb = "mb-0",
  size = "text-base",

  children,
}) {
  return (
    <div
      className={`select-custom ${width} ${height} ${rounded} ${mb} ${mr} ${ml} ${mt} ${size}`}
    >
      <select onChange={onChange} value={value} name={name}>
        {children}
      </select>
    </div>
  );
}
