import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Marquee from "react-fast-marquee";

function OurClients({ clients = {} }) {
  return (
    <>
      <p className="font-medium text-[28px] sm:text-[32px] leading-10 sm:leading-[48px] text-white text-center">
        {clients?.title}
      </p>
      <div className="dark-gradient mt-9 mb-[62px] pt-[40px] sm:pt-[83px] pb-[40px] sm:pb-[85px]">
        <Marquee className="w-full" gradient={false} speed={80}>
          {clients?.clientList.map((item, index) => (
            <div
              key={index}
              className="mx-[90px] flex items-center justify-center"
            >
              <GatsbyImage
                image={item?.logo?.asset?.gatsbyImageData}
                alt="client-images"
              />
            </div>
          ))}
        </Marquee>
      </div>
    </>
  );
}

export default React.memo(OurClients);
