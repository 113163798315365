export function emailValidate(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase().trim());
}

export function passwordValidate(string) {
  const regex = /^(?:(?:^| )[a-z]+ *){4,}$/;
  return regex.test(String(string).trim());
}

export function isNumber(string) {
  const regex = /^\d+$/;
  return regex.test(String(string).toLowerCase().trim());
}

export function cardValidate(number) {
  const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  // const amexpRegEx = /^(?:3[47][0-9]{13})$/;
  // const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  var isValid = false;
  if (visaRegEx.test(number)) {
    isValid = true;
  } else if (mastercardRegEx.test(number)) {
    isValid = true;
  }
  // else if (amexpRegEx.test(number)) {
  //   isValid = true;
  // } else if (discovRegEx.test(number)) {
  //   isValid = true;
  // }
  return isValid;
}

export function numberAndSlash(string) {
  const regex = /^\d*([\/]?\d*)$/;
  return regex.test(String(string).trim());
}
